import { makeStyles } from '@material-ui/core'

export const stepFadeStyles = makeStyles((theme) => ({
  root: {
    animation: `$fade 1.5s ${theme.transitions.easing.easeInOut}`,
  },
  '@keyframes fade': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}))