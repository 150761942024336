import React from 'react'
import { stepFadeStyles } from './styles'

const StepFade: React.FC = ({
  children,
}) => {
  const classes = stepFadeStyles()

  return (
    <div className={classes.root}>
      {children}
    </div>
  )
}

export default StepFade
