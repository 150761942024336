import React from 'react'

import { useRouter } from 'next/router'

import cfg from '../../config'
import StepFade from '../../components/ui/StepFade'
import MetaTags from '../../components/MetaTags'
import { LOCALES } from '../../const/env'
import { GetStaticPaths, GetStaticProps } from 'next'
import { I_STEP_PAGE_PROPS } from '@/src/types/types'


const StepPage: React.FC<I_STEP_PAGE_PROPS> = ({
  meta,
  data,
}) => {
  const router = useRouter()

  const { id } = router.query

  if (id === undefined || Array.isArray(id)) {
    return null
  }

  const Step = cfg.stepComponents[id]

  if (!Step) {
    return null
  }

  return (
    <StepFade>
      <MetaTags meta={meta} />
      <Step data={data} />
    </StepFade>
  )
}


export const getStaticProps: GetStaticProps = async ({
  locale,
  params,
}) => {
  const { id } = params
  let page = {
    meta: {},
  }

  if (id) {
    await import(`../../../content/pages/${id}.json`)
      .then(x => page = x.default)
  }
  const cookie = await import('../../../content/gdpr/cookie.json')

  return {
    props: {
      gdpr: {
        cookie: cookie[locale || 'de'] || {},
      },
      meta: {
        ...page?.meta?.[locale || 'de'],
        ...page?.meta,
      },
      data: {
        ...page[locale || 'de'],
      },
    },
  }
}


export const getStaticPaths: GetStaticPaths = async () => {
  const steps = Object.keys(cfg.stepComponents)
  const paths = LOCALES.map(locale => {
    return steps.map(id => `/${locale}/step/${id}`)
  }).flat()

  return {
    paths,
    fallback: false,
  }
}


export default StepPage